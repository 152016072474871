


















































import auth from '@/composables/auth'
import { defineComponent, onBeforeMount, ref } from '@vue/composition-api'
import { fetchLive } from '@/inc/utils'
import { AxiosResponse } from 'axios'
import { useActions, useState } from '@u3u/vue-hooks'

import * as sso from '@/composables/sso'
import Message from '@/components/ui/Message.vue'
import CustomLink from '@/components/g/CustomLink.vue'

export default defineComponent({
  name: 'my-success',
  components: {
    Message,
    CustomLink,
  },

  setup(_props, ctx) {
    const { $router } = ctx.root
    const { fetchI18nOnly } = useActions('my', ['fetchI18nOnly'])
    const i18n = ref<Record<string, any>>()
    const communeState = {
      ...useState('commune', ['i18n']),
    }
    const onAction = () => {
      if (auth.currentUser.value) {
        $router.push({
          name: 'CommuneDashboard',
        })
      } else {
        sso.authorize()
      }
    }

    onBeforeMount(async () => {
      fetchI18nOnly()
      const { route: createRoute } = $router.resolve('/create')
      const res: AxiosResponse = await fetchLive(createRoute)

      i18n.value = res.data.content || {}
    })

    return {
      i18n,
      myI18n: useState('my', ['i18n'])?.i18n,
      onAction,
      communeI18n: communeState.i18n,
    }
  },
})
