var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-password wrapper m-l"},[(_vm.i18n && _vm.myI18n)?_c('div',{staticClass:"col-xxxs-20 col-s-14"},[_c('h1',{staticClass:"mb-m"},[_vm._v(" "+_vm._s(_vm.isPpp ? _vm.myI18n.confirmCreation.ppp.title : 'MyRESA')+" ")]),(_vm.isPpp)?[_c('div',{staticClass:"fatline h3 mb-m",domProps:{"innerHTML":_vm._s(_vm.myI18n.confirmCreation.ppp.congratulations)}})]:[(_vm.i18n.confirmCreation.successCreation.title)?_c('div',{staticClass:"fatline h3 mb-m",domProps:{"innerHTML":_vm._s(_vm.i18n.confirmCreation.successCreation.title)}}):_vm._e()],(!_vm.isPpp && _vm.i18n.confirmCreation.successCreation.htmltext)?_c('div',{staticClass:"mb-m",domProps:{"innerHTML":_vm._s(_vm.i18n.confirmCreation.successCreation.htmltext)}}):_vm._e(),(
        !_vm.isPpp &&
        _vm.myI18n.loginPage.advantages.items &&
        _vm.myI18n.loginPage.advantages.items.length > 0
      )?_c('ul',{staticClass:"check-list"},_vm._l((_vm.myI18n.loginPage.advantages.items),function(item,index){return _c('li',{key:index,staticClass:"check-list__item small mb-xxs intro__list__item"},[_vm._v(" "+_vm._s(item.text)+" ")])}),0):_vm._e(),(_vm.myI18n.confirmCreation.button)?_c('g-action',{staticClass:"mt-l",attrs:{"content":{
        label: _vm.myI18n.confirmCreation.button.label,
        modifiers: ['btn'],
        icon: 'arrow',
        tag: 'button',
      }},on:{"on-click":_vm.onAction}}):_vm._e()],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }